.custom-alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .custom-alert-box {
    background-color: #f0e68c;
    border: 2px solid #daa520;
    border-radius: 10px;
    padding: 20px;
    max-width: 80%;
    text-align: center;
  }
  
  .custom-alert-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .custom-alert-message {
    color: #8b4513;
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .custom-alert-button {
    background-color: #daa520;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .custom-alert-button:hover {
    background-color: #cd950c;
  }