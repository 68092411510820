/* Base styles */
.start-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-image: url('../images/fantasy-background.jpg');
  background-size: cover;
  background-position: center;
  font-family: 'Arial', sans-serif;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}

/* Overlay for better text readability */
.start-screen::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

/* Ensure all direct children are above the overlay */
.start-screen > * {
  position: relative;
  z-index: 2;
}

/* Typography */
.start-screen__title {
  color: #f0e6d2;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  margin-bottom: 15px;
  font-size: 3em;
}

.start-screen__subtitle {
  color: #f0e6d2;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  margin-bottom: 30px;
  font-size: 1.5em;
}

/* Language selector styles */
.start-screen__language-container {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.start-screen__language-container::-webkit-scrollbar {
  display: none;
}

.start-screen__language-selector {
  display: inline-flex;
  gap: 10px;
  padding: 5px;
}

.start-screen__language-button {
  background-color: rgba(255, 19, 19, 0.7);
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
  outline: none;
  color: #ffffff; /* Added for better contrast */
}

.start-screen__language-button:hover,
.start-screen__language-button:focus {
  background-color: rgba(240, 230, 210, 0.9);
  box-shadow: 0 0 0 2px rgba(240, 230, 210, 0.5);
  color: #000000; /* Added for better contrast when hovered/focused */
}

.start-screen__language-button--active {
  background-color: #4caf50;
  color: white;
}

/* Coin styles */
.start-screen__coin-container {
  text-align: center;
  margin-bottom: 20px;
  position: relative;
  width: 300px;
  height: 300px;
  perspective: 1000px;
}

.start-screen__coin {
  width: 150px;
  height: 150px;
  cursor: pointer;
  transition: transform 0.3s;
  transform-style: preserve-3d;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}

.start-screen__coin:hover,
.start-screen__coin:focus {
  transform: rotateY(180deg);
}

.start-screen__coin:active {
  transform: rotateY(180deg) scale(0.95);
}

.start-screen__coin--animate {
  animation: start-screen-coin-tap 0.3s ease-in-out;
}

@keyframes start-screen-coin-tap {
  0% { transform: rotateY(0) scale(1); }
  50% { transform: rotateY(90deg) scale(0.95); }
  100% { transform: rotateY(180deg) scale(1); }
}

/* Pop-up text styles */
.start-screen__pop-up-text {
  position: absolute;
  font-size: 24px;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  transform: translate(-50%, -100%) translateZ(50px);
  animation: start-screen-pop-up 1s ease-in-out;
}

@keyframes start-screen-pop-up {
  0% { opacity: 0; transform: translate(-50%, -100%) translateY(0) translateZ(0); }
  50% { opacity: 1; transform: translate(-50%, -110%) translateZ(50px); }
  100% { opacity: 0; transform: translate(-50%, -120%) translateZ(100px); }
}

/* Tutorial styles */
.start-screen__tutorial-text {
  max-width: 600px;
  text-align: center;
  margin-bottom: 20px;
  color: #f0e6d2;
  line-height: 1.6;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 0, 0, 0.6);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Tutorial button styles */
.start-screen__tutorial-buttons {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.start-screen__tutorial-button {
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  outline: none;
}

.start-screen__tutorial-button--primary {
  background-color: #4caf50;
}

.start-screen__tutorial-button--secondary {
  background-color: #f44336;
}

.start-screen__tutorial-button:hover,
.start-screen__tutorial-button:focus {
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.start-screen__tutorial-button--primary:hover,
.start-screen__tutorial-button--primary:focus {
  background-color: #45a049;
}

.start-screen__tutorial-button--secondary:hover,
.start-screen__tutorial-button--secondary:focus {
  background-color: #d32f2f;
}

/* Global styles */
* {
  -webkit-tap-highlight-color: transparent;
}

*:focus {
  outline: none;
}

*:focus-visible {
  box-shadow: 0 0 0 2px rgba(240, 230, 210, 0.5);
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .start-screen__title {
    font-size: 2.5em;
  }

  .start-screen__subtitle {
    font-size: 1.2em;
  }

  .start-screen__coin-container {
    width: 250px;
    height: 250px;
  }

  .start-screen__coin {
    width: 120px;
    height: 120px;
  }
}