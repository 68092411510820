:root {
  --primary-color: #d4af37;
  --secondary-color: #8b4513;
  --background-color: #f4e1c1;
  --text-color: #2c1e0f;
  --border-color: #a0522d;
  --button-hover-color: #ffd700;
  --disabled-color: #a9a9a9;
  --story-text-color: #ffffff; /* New variable for story text color */
}

.weapon-craft-container {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: 'Papyrus', 'Copperplate', fantasy;
  padding: 20px;
  border: 2px solid var(--border-color);
  border-radius: 10px;
  max-width: 600px;
  margin: 0 auto;
  max-height: 80vh;
  overflow-y: auto;
}

h2 {
  color: var(--secondary-color);
  text-align: center;
  font-size: 2em;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.resources {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.resources p {
  margin: 5px 0;
  font-size: 1.1em;
}

.crafting-section, .arrow-crafting {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
  padding: 15px;
  background-color: rgba(0, 0, 0, 0.6); /* Darker background for contrast */
  border-radius: 5px;
}

.story-text {
  font-style: italic;
  color: var(--story-text-color); /* Using the new white color for story text */
  margin-bottom: 15px;
  line-height: 1.6;
  text-align: justify;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); /* Added shadow for better readability */
}

button {
  background-color: var(--primary-color);
  color: var(--text-color);
  border: none;
  padding: 10px 15px;
  font-size: 1em;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 5px;
  font-family: inherit;
  text-transform: uppercase;
  letter-spacing: 1px;
}

button:hover:not(:disabled) {
  background-color: var(--button-hover-color);
  transform: translateY(-2px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

button:disabled {
  background-color: var(--disabled-color);
  cursor: not-allowed;
}

.crafting-status {
  font-style: italic;
  color: var(--secondary-color);
  text-align: center;
  margin-top: 10px;
}

/* Egyptian-inspired decorative elements */
.weapon-craft-container::before,
.weapon-craft-container::after {
  content: "☥";  /* Ankh symbol */
  font-size: 2em;
  color: var(--secondary-color);
  display: block;
  text-align: center;
  margin: 10px 0;
}

/* Responsive design */
@media (max-width: 600px) {
  .weapon-craft-container {
    padding: 10px;
  }

  h2 {
    font-size: 1.5em;
  }

  button {
    font-size: 0.9em;
  }

  .resources {
    grid-template-columns: repeat(2, 1fr);
  }

  .story-text {
    font-size: 0.9em;
  }
}