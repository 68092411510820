:root {
  --furnace-glow: #ff6600;
}

body {
  background-color: #1a1a1a;
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
}

.smelting-puzzle {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(45deg, #1a1a1a, #2c2c2c);
  overflow: hidden;
}

.furnace {
  background-color: #333;
  border-radius: 20px;
  padding: 40px;
  box-shadow: 0 0 50px rgba(255, 102, 0, calc(var(--temperature, 0) / 100));
  position: relative;
  overflow: hidden;
}

.furnace::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, 
    rgba(255, 0, 0, 0.5), 
    rgba(255, 165, 0, 0.5)
  );
  opacity: calc(var(--temperature, 0) / 100);
  z-index: -1;
  filter: blur(20px);
}

h1 {
  color: #fff;
  text-align: center;
  font-size: 2.5em;
  text-shadow: 0 0 10px var(--furnace-glow);
  margin-bottom: 20px;
}

.score, .attempts, .current-ore {
  color: #fff;
  font-size: 1.2em;
  margin: 10px 0;
  text-shadow: 0 0 5px var(--furnace-glow);
}

.controls {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
}

.knob-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.knob {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: conic-gradient(from 0deg, var(--color) var(--percentage), #444 var(--percentage));
  box-shadow: 
    0 0 0 10px #222,
    0 0 0 15px #333,
    0 5px 15px rgba(0, 0, 0, 0.6);
  position: relative;
  cursor: pointer;
}

.knob::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  background-color: #222;
  border-radius: 50%;
  z-index: 1;
}

.knob-dial {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 2;
}

.knob-indicator {
  width: 4px;
  height: 40%;
  background-color: var(--color);
  border-radius: 2px;
  transform-origin: bottom center;
  box-shadow: 0 0 10px var(--color);
}

.knob-value {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  text-shadow: 0 0 5px var(--color);
  z-index: 3;
}

.knob-label {
  margin-top: 10px;
  color: #fff;
  font-size: 16px;
  text-shadow: 0 0 5px var(--furnace-glow);
}

.temperature-knob {
  background: conic-gradient(
    from 0deg,
    hsl(calc(60 - (var(--percentage) * 0.6)), 100%, 50%) var(--percentage),
    #444 var(--percentage)
  );
}

.smelt-button {
  display: block;
  margin: 30px auto 0;
  padding: 15px 30px;
  font-size: 1.2em;
  font-weight: bold;
  color: #fff;
  background-color: #ff4500;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 5px 15px rgba(255, 69, 0, 0.4);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
}

.smelt-button:hover {
  background-color: #ff6347;
  transform: translateY(-2px);
  box-shadow: 0 7px 20px rgba(255, 69, 0, 0.6);
}

.smelt-button:active {
  transform: translateY(1px);
  box-shadow: 0 3px 10px rgba(255, 69, 0, 0.4);
}

.smelt-button:disabled {
  background-color: #666;
  cursor: not-allowed;
  box-shadow: none;
}

.game-over {
  text-align: center;
  font-size: 2em;
  font-weight: bold;
  color: #ff4136;
  margin-top: 20px;
  text-shadow: 0 0 10px #ff4136;
  animation: pulse 1s infinite alternate;
}

@keyframes pulse {
  from { opacity: 0.5; }
  to { opacity: 1; }
}

@media (max-width: 600px) {
  .controls {
    flex-direction: column;
  }
  
  .knob-container {
    margin-bottom: 20px;
  }
}