.ae-minigames-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ae-minigames-content {
  width: 100%;
  max-width: 600px;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
}

.ae-minigame-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ae-minigames-title {
  color: #ffd700;
  text-align: center;
  margin-bottom: 20px;
}

.ae-minigames-total-score {
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
}
  
  .minigames-card {
    background-color: rgba(250, 240, 230, 0.8);
    border: 3px solid #d2b48c;
    border-radius: 15px;
    padding: 15px;
    text-align: center;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 100px); /* Adjust based on header and button heights */
  }
  
  .minigames-card-image {
    width: 100%;
    height: 40%;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 10px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  }
  
  .minigames-card-title {
    color: #8b4513;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: bold;
  }
  
  .minigames-card-description {
    font-size: 14px;
    margin-bottom: 10px;
    color: #5e4b3b;
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  
  .minigames-button {
    background-color: #8b4513;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 16px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    font-family: 'Cinzel', serif;
    text-transform: uppercase;
    letter-spacing: 1px;
    align-self: center;
  }
  
  .minigames-button:hover:not(:disabled) {
    background-color: #a0522d;
    transform: translateY(-3px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }
  
  .minigames-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .minigames-get-weapon-button {
    width: 80%;
    max-width: 300px;
    margin: 20px auto 0;
    background-color: #4CAF50;
    font-size: 18px;
    padding: 12px 25px;
  }
  
  .minigames-get-weapon-button:hover {
    background-color: #45a049;
  }
  
  .minigames-cooldown-text {
    font-size: 14px;
    color: #8b4513;
    margin-top: 5px;
    font-style: italic;
  }
  
  /* Modal styles remain the same */
  .minigames-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .minigames-modal-content {
    background-color: #f4e9d5;
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
    max-width: 90%;
    max-height: 90%;
    overflow: auto;
  }
  
  .minigames-modal-close {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 24px;
    color: #fff;
    cursor: pointer;
    background: none;
    border: none;
    padding: 5px 10px;
  }
  
  .minigames-modal-close:hover {
    color: #ffd700;
  }
  
  .minigames-game-over-modal {
    text-align: center;
  }
  
  .minigames-game-over-title {
    color: #8b4513;
    font-size: 24px;
    margin-bottom: 15px;
  }
  
  .minigames-game-over-text {
    font-size: 16px;
    color: #fdfdfd;
    margin-bottom: 10px;
  }
  .ae-hammer-time-fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .ae-hammer-time-fullscreen canvas {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }