@keyframes fadeInOut {
  0%, 100% { opacity: 0; transform: translateY(10px); }
  50% { opacity: 1; transform: translateY(0); }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-size: cover;
  background-position: center;
  text-align: center;
  font-family: 'Cinzel', serif;
  color: #ffd700;
}

.loading-content {
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(255, 215, 0, 0.3);
  animation: fadeIn 1s ease-in;
}

.loading-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.loading-text {
  font-size: 1.2rem;
  margin-top: 20px;
}

.dancing-symbols {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  height: 60px;
}

.dancing-symbol {
  font-size: 3rem;
  margin: 0 10px;
  display: inline-block;
  animation: fadeInOut 1s infinite ease-in-out;
}

.dancing-symbol:nth-child(2) {
  animation-delay: 0.2s;
}

.dancing-symbol:nth-child(3) {
  animation-delay: 0.4s;
}

.progress-bar {
  width: 80%;
  height: 20px;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 20px auto;
  border-radius: 10px;
  overflow: hidden;
}

.progress {
  height: 100%;
  background: linear-gradient(45deg, #ffd700, #ff8c00);
  border-radius: 10px;
  transition: width 0.3s ease-in-out;
}