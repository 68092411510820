/* Weapon Testing Game Styles */
.weapon-game .game-container {
  text-align: center;
  padding: 30px;
  background: linear-gradient(135deg, #2c3e50, #34495e);
  border-radius: 20px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.4);
}

.weapon-game .title {
  color: #ecf0f1;
  text-shadow: 0 0 15px #3498db;
  font-size: 32px;
  margin-bottom: 25px;
  font-family: 'Arial', sans-serif;
  letter-spacing: 2px;
}

.weapon-game .score {
  color: #e74c3c;
  font-size: 24px;
  margin-bottom: 25px;
  font-weight: bold;
}

.weapon-game .grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  gap: 20px;
  max-width: 350px;
  margin: auto;
  background-color: #1a1a1a;
  padding: 20px;
  border-radius: 15px;
  box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.5);
}

.weapon-game .target {
  width: 100%;
  padding-bottom: 100%;
  background-color: rgba(52, 152, 219, 0.3);
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  position: relative;
  overflow: hidden;
}

.weapon-game .target:before {
  content: '';
  position: absolute;
  top: 10%;
  left: 10%;
  right: 10%;
  bottom: 10%;
  background: radial-gradient(circle at 30% 30%, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
  border-radius: 50%;
  opacity: 0.6;
}

.weapon-game .target:hover {
  transform: scale(1.1) rotate(5deg);
}

.weapon-game .target.active {
  background-color: #e74c3c;
  box-shadow: 0 0 30px #e74c3c, 0 0 60px #e74c3c, 0 0 90px #e74c3c;
  animation: pulse 0.6s infinite alternate;
}

@keyframes pulse {
  0% { transform: scale(1) rotate(0deg); }
  100% { transform: scale(1.15) rotate(5deg); }
}

.weapon-game .game-over {
  margin-top: 30px;
  color: #ecf0f1;
  font-size: 28px;
  font-weight: bold;
  text-shadow: 0 0 10px #e74c3c;
}

.weapon-game .start-button,
.weapon-game .restart-button,
.weapon-game .close-button {
  padding: 12px 25px;
  font-size: 18px;
  background-color: #2ecc71;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 15px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
}

.weapon-game .start-button:hover,
.weapon-game .restart-button:hover,
.weapon-game .close-button:hover {
  background-color: #27ae60;
  transform: translateY(-3px);
  box-shadow: 0 8px 15px rgba(46, 204, 113, 0.4);
}