.achievements-scroll {
  background-color: #f2e8c9; /* Light papyrus color */
  border: 2px solid #8b4513; /* Brown border */
  border-radius: 10px;
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.scroll-title {
  text-align: center;
  color: #8b4513; /* Brown color */
  font-size: 24px;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
  border-bottom: 2px solid #8b4513;
  padding-bottom: 10px;
}

.achievements-list {
  max-height: 500px;
  overflow-y: auto;
  padding-right: 10px;
}

.achievements-list::-webkit-scrollbar {
  width: 8px;
}

.achievements-list::-webkit-scrollbar-track {
  background: #d3bc8d; /* Light brown */
}

.achievements-list::-webkit-scrollbar-thumb {
  background: #8b4513; /* Brown */
  border-radius: 4px;
}

.achievement-item {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #d3bc8d;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 15px;
  transition: transform 0.2s ease-in-out;
}

.achievement-item:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.achievement-icon {
  font-size: 24px;
  margin-right: 15px;
  min-width: 30px;
  text-align: center;
}

.achievement-details {
  flex: 1;
}

.achievement-details h3 {
  color: #8b4513;
  margin: 0 0 5px 0;
  font-size: 18px;
}

.achievement-details p {
  color: #555;
  margin: 0 0 5px 0;
  font-size: 14px;
}

.achievement-reward {
  color: #4caf50; /* Green color for reward */
  font-weight: bold;
}

.achievement-item.completed {
  background-color: #e6f7d9; /* Light green background for completed achievements */
}

.achievement-item.completed .achievement-icon {
  color: #4caf50; /* Green color for completed achievement icon */
}

.notification-dot {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
}

@media (max-width: 600px) {
  .achievements-scroll {
    padding: 10px;
  }

  .scroll-title {
    font-size: 20px;
  }

  .achievement-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .achievement-icon {
    margin-bottom: 10px;
  }
}