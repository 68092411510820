:root {
  --sand-light: #e9a443;
  --sand-dark: #d3b998;
  --glass-bg: rgba(99, 136, 120, 0.597);
  --glass-border: rgba(255, 255, 255, 0.25);
  --text-color: #3a2f2d;
  --accent-color: #c0a080;
  --dark-gold: #0e7107e7;
  --error-color: #ff6b6b;
  --shadow: rgba(0, 0, 0, 0.1);
}

body {
  background: linear-gradient(135deg, var(--sand-light), var(--sand-dark));
  background-attachment: fixed;
  font-family: 'Roboto', sans-serif;
  color: var(--text-color);
  min-height: 100vh;
}

.referral-system {
  max-width: 100%;
  padding: 20px;
  background: var(--glass-bg);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  border: 1px solid var(--glass-border);
  box-shadow: 
    0 8px 32px 0 var(--shadow),
    inset 0 0 0 1px rgba(255, 255, 255, 0.1);
  animation: fadeIn 0.5s ease-out;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

.referral-system__header {
  font-size: 28px;
  margin-bottom: 20px;
  text-align: center;
  color: var(--text-color);
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5);
  animation: slideDown 0.5s ease-out;
}

@keyframes slideDown {
  from { transform: translateY(-20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

.referral-system__subheader {
  font-size: 20px;
  margin-bottom: 15px;
  color: var(--text-color);
  animation: fadeIn 0.5s ease-out 0.2s both;
}

.referral-system__code-section,
.referral-system__stats,
.referral-system__friends-section {
  background: var(--glass-bg);
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 25px;
  border: 1px solid var(--glass-border);
  backdrop-filter: blur(5px);
  animation: slideIn 0.5s ease-out;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

@keyframes slideIn {
  from { transform: translateX(-20px); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
}

.referral-system__code-section:hover,
.referral-system__stats:hover,
.referral-system__friends-section:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.referral-system__code-display {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(255, 255, 255, 0.2);
  padding: 10px;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.referral-system__code-display:hover {
  background: rgba(255, 255, 255, 0.3);
}

.referral-system__code {
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 2px;
  color: var(--text-color);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

.referral-system__copy-btn,
.referral-system__share-btn,
.referral-system__claim-btn {
  padding: 12px 20px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  background: var(--dark-gold);
  color: #ffffff;
  box-shadow: 
    0 4px 6px rgba(0, 0, 0, 0.1),
    0 1px 3px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 rgba(255, 255, 255, 0.1),
    inset 0 -1px 0 rgba(0, 0, 0, 0.2),
    0 8px 10px rgba(34, 51, 28, 0.52);
  position: relative;
  top: 0;
}

.referral-system__copy-btn:hover,
.referral-system__claim-btn:hover {
  background: #9b7300;
  transform: translateY(-2px);
  box-shadow: 
    0 7px 14px rgba(0, 0, 0, 0.1),
    0 3px 6px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 rgba(255, 255, 255, 0.1),
    inset 0 -1px 0 rgba(0, 0, 0, 0.2),
    0 12px 16px rgba(184, 134, 11, 0.3);
}

.referral-system__copy-btn:active,
.referral-system__claim-btn:active {
  transform: translateY(1px);
  box-shadow: 
    0 2px 4px rgba(0, 0, 0, 0.1),
    0 1px 2px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 rgba(255, 255, 255, 0.1),
    inset 0 -1px 0 rgba(0, 0, 0, 0.2),
    0 4px 6px rgba(184, 134, 11, 0.2);
}

.referral-system__share-btn,
.referral-system__claim-btn {
  width: 100%;
  margin-top: 15px;
}

.referral-system__friends-section {
  max-height: 300px;
  display: flex;
  flex-direction: column;
}

.referral-system__friends-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 2px solid var(--glass-border);
}

.referral-system__refresh-btn {
  padding: 8px;
  background: transparent;
  color: var(--dark-gold);
  box-shadow: none;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.referral-system__refresh-btn:hover {
  transform: rotate(180deg);
}

.referral-system__friend-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow-y: auto;
  flex-grow: 1;
}

.referral-system__friend-item {
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  border-bottom: 1px solid var(--glass-border);
  transition: background-color 0.3s ease;
}

.referral-system__friend-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.referral-system__friend-item:last-child {
  border-bottom: none;
}

.referral-system__friend-name {
  font-weight: bold;
  flex-grow: 1;
  margin-right: 10px;
}

.referral-system__friend-reward {
  color: var(--dark-gold);
  font-weight: bold;
  white-space: nowrap;
}

.referral-system__friend-list::-webkit-scrollbar {
  width: 6px;
}

.referral-system__friend-list::-webkit-scrollbar-track {
  background: var(--glass-bg);
  border-radius: 3px;
}

.referral-system__friend-list::-webkit-scrollbar-thumb {
  background: var(--dark-gold);
  border-radius: 3px;
}

.referral-system__friend-list::-webkit-scrollbar-thumb:hover {
  background: #9b7300;
}

.referral-system__loading,
.referral-system__no-friends,
.referral-system__error {
  text-align: center;
  padding: 20px 0;
  color: var(--text-color);
  font-style: italic;
}

.referral-system__error {
  color: var(--error-color);
}

.referral-system__claim-section {
  text-align: center;
  margin-bottom: 25px;
}

.referral-system__stat-item {
  margin-bottom: 10px;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: transform 0.3s ease;
}

.referral-system__stat-item:hover {
  transform: translateX(5px);
}

.referral-system__stat-item::before {
  content: '☥';
  margin-right: 10px;
  color: var(--dark-gold);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.spin {
  animation: spin 1s linear infinite;
}

@media (min-width: 768px) {
  .referral-system {
    max-width: 500px;
    margin: 20px auto;
  }
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: var(--sand-light);
}

::-webkit-scrollbar-thumb {
  background: var(--dark-gold);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #9b7300;
}

@keyframes heartbeat {
  0%, 100% {
    transform: scale(1);
    background-color: var(--dark-gold);
  }
  50% {
    transform: scale(1.08);
    background-color: #416ec2;
  }
}

.referral-system__share-btn {
  animation: heartbeat 1.5s ease-in-out infinite;
  transition: all 0.3s ease, background-color 0.3s ease, transform 0.3s ease;
}

.referral-system__share-btn:hover {
  animation: none;
  background: #e74c3c;
  transform: scale(1.05);
  box-shadow: 
    0 7px 14px rgba(0, 0, 0, 0.1),
    0 3px 6px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 rgba(255, 255, 255, 0.1),
    inset 0 -1px 0 rgba(0, 0, 0, 0.2),
    0 12px 16px rgba(231, 76, 60, 0.3);
}

.referral-system__share-btn:active {
  animation: none;
  background: #c0392b;
  transform: scale(0.95);
  box-shadow: 
    0 2px 4px rgba(0, 0, 0, 0.1),
    0 1px 2px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 rgba(255, 255, 255, 0.1),
    inset 0 -1px 0 rgba(0, 0, 0, 0.2),
    0 4px 6px rgba(192, 57, 43, 0.2);
}