.box {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #ccc;
    border: 2px solid #333;
    transition: all 0.3s ease;
    cursor: pointer;
  }
  
  .box:hover {
    transform: scale(1.1);
  }
  
  .box.lit {
    background-color: yellow;
    animation: pulse 0.5s infinite alternate;
  }
  
  .box.glow {
    animation: glow 1s infinite alternate;
  }
  
  @keyframes pulse {
    from { transform: scale(1); }
    to { transform: scale(1.1); }
  }
  
  @keyframes glow {
    from { box-shadow: 0 0 5px yellow; }
    to { box-shadow: 0 0 20px yellow, 0 0 30px orange; }
  }
  
  .hidden-s { border-color: #ff0000; }
  .hidden-t { border-color: #00ff00; }
  .hidden-o { border-color: #0000ff; }
  .hidden-p { border-color: #ff00ff; }
  .hidden-r { border-color: #ffff00; }
  .hidden-u { border-color: #00ffff; }
  .hidden-n { border-color: #ff8000; }
  .hidden-w { border-color: #8000ff; }
  .hidden-h { border-color: #0080ff; }
  .hidden-y { border-color: #ff0080; }
  .hidden-e { border-color: #80ff00; }
  .hidden-l { border-color: #ff8080; }
  .hidden-k { border-color: #8080ff; }