/* Ancient Pyramid Merchant CSS */

:root {
  --merchant-sand-light: #d9bc83;
  --merchant-sand-dark: #d2b48c;
  --merchant-stone: #9d8567;
  --merchant-gold: #ffd700;
  --merchant-hieroglyph: #8b4513;
  --merchant-papyrus: #d1c768;
  --merchant-nile: #007ba7;
}

.merchant-body {
  background-color: var(--merchant-sand-light);
  color: var(--merchant-hieroglyph);
  font-family: 'Papyrus', 'Copperplate', fantasy;
}

.merchant-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.merchant-container {
  background-color: var(--merchant-papyrus);
  border: 4px solid var(--merchant-gold);
  border-radius: 10px;
  padding: 20px;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
  position: relative;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.merchant-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: var(--merchant-nile);
}

.merchant-title {
  color: var(--merchant-nile);
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
  text-shadow: 2px 2px var(--merchant-sand-dark);
}

.merchant-tabs {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.merchant-tab-button {
  background-color: var(--merchant-stone);
  color: var(--merchant-sand-light);
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  transition: background-color 0.3s;
}

.merchant-tab-button.active {
  background-color: var(--merchant-nile);
}

.merchant-items-list {
  max-height: 300px;
  overflow-y: auto;
  border: 2px solid var(--merchant-stone);
  border-radius: 5px;
  padding: 10px;
}

.merchant-item-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  background-color: var(--merchant-sand-dark);
  border-radius: 5px;
}

.merchant-button {
  background-color: var(--merchant-gold);
  color: var(--merchant-hieroglyph);
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.merchant-button:hover {
  background-color: var(--merchant-nile);
  color: var(--merchant-sand-light);
}

.merchant-button:disabled {
  background-color: var(--merchant-stone);
  cursor: not-allowed;
}

.merchant-message {
  background-color: var(--merchant-sand-dark);
  color: var(--merchant-hieroglyph);
  padding: 10px;
  border-radius: 5px;
  margin-top: 20px;
  text-align: center;
}

/* Scrollbar Styling */
.merchant-container::-webkit-scrollbar {
  width: 10px;
}

.merchant-container::-webkit-scrollbar-track {
  background: var(--merchant-sand-light);
}

.merchant-container::-webkit-scrollbar-thumb {
  background: var(--merchant-stone);
  border-radius: 5px;
}

.merchant-container::-webkit-scrollbar-thumb:hover {
  background: var(--merchant-nile);
}

/* Mobile-specific adjustments */
@media (max-width: 768px) {
  .merchant-container {
    padding: 15px;
    max-width: 95%;
    max-height: 95%;
  }

  .merchant-title {
    font-size: 20px;
  }

  .merchant-tab-button {
    padding: 8px 15px;
    font-size: 14px;
  }

  .merchant-item-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .merchant-item-row .merchant-button {
    margin-top: 10px;
    width: 100%;
  }
}