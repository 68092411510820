:root {
  --bg-primary: #f4e1c1;    /* Light sand color */
  --bg-secondary: #d2b48c;  /* Darker sand color */
  --text-primary: #3e2723;  /* Dark brown for text */
  --accent-primary: #ffd700; /* Gold color for accents */
  --accent-secondary: #b8860b; /* Darker gold for hover states */
  --button-text: #ffffff;   /* White text on buttons */
}

.settings-container {
  padding: 20px;
  background-color: var(--bg-primary);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  margin: 0 auto;
}

h3 {
  margin-bottom: 20px;
  text-align: center;
  color: var(--text-primary);
  font-size: 24px;
  font-weight: bold;
}

.settings-options {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.setting-button {
  display: flex;
  align-items: center;
  background-color: var(--bg-secondary);
  border: 2px solid var(--accent-primary);
  border-radius: 8px;
  padding: 12px 15px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.setting-button:hover {
  background-color: var(--accent-primary);
}

.setting-button img {
  width: 24px;
  height: 24px;
  margin-right: 15px;
  filter: invert(1);
}

.setting-button span {
  font-size: 16px;
  color: var(--text-primary);
  font-weight: bold;
}

.setting-button:hover span {
  color: var(--button-text);
}

.language-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.language-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-secondary);
  border: 2px solid var(--accent-primary);
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.language-button:hover {
  background-color: var(--accent-primary);
}

.language-button img {
  width: 24px;
  height: 24px;
  margin-bottom: 5px;
  filter: invert(1);
}

.language-button span {
  font-size: 14px;
  color: var(--text-primary);
  font-weight: bold;
  text-align: center;
}

.language-button:hover span {
  color: var(--button-text);
}

.language-button.active {
  background-color: var(--accent-primary);
}

.language-button.active span {
  color: var(--button-text);
}

.close-button {
  display: block;
  width: 100%;
  padding: 12px;
  margin-top: 20px;
  background-color: var(--accent-primary);
  color: var(--button-text);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.close-button:hover {
  background-color: var(--accent-secondary);
}

@media (max-width: 320px) {
  .language-grid {
    grid-template-columns: 1fr;
  }

  .setting-button, .language-button {
    padding: 10px;
  }

  .setting-button img, .language-button img {
    width: 20px;
    height: 20px;
  }

  .setting-button span, .language-button span {
    font-size: 14px;
  }
}