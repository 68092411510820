.legend-weapons-lvl4-container {
  height: 80vh;
  overflow: hidden;
  position: relative;

  background-size: cover;
}

.legend-weapons-lvl4 {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: rgba(244, 233, 213, 0.9);
  color: #3c2a1e;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  font-family: 'Cinzel', serif;

  scrollbar-width: thin;
  scrollbar-color: #8b4513 rgba(244, 233, 213, 0.5);
}

.legend-weapons-lvl4::-webkit-scrollbar {
  width: 8px;
}

.legend-weapons-lvl4::-webkit-scrollbar-track {
  background: rgba(244, 233, 213, 0.5);
}

.legend-weapons-lvl4::-webkit-scrollbar-thumb {
  background-color: #8b4513;
  border-radius: 4px;
}

.legend-weapons-lvl4 h2 {
  color: #8b4513;
  text-align: center;
  font-size: 32px;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  font-weight: bold;
}

.instructions {
  background-color: rgba(250, 240, 230, 0.8);
  border: 2px solid #d2b48c;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  max-height: 200px;
  overflow-y: auto;
  font-family: 'Merriweather', serif;
}

.instructions pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: 'Courier Prime', monospace;
  font-size: 14px;
  line-height: 1.6;
  color: #3c2a1e;
}

.weapon-selection {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 25px;
  margin-top: 30px;
}

.weapon-card {
  background-color: rgba(250, 240, 230, 0.8);
  border: 3px solid #d2b48c;
  border-radius: 15px;
  padding: 20px;
  text-align: center;
  transition: all 0.3s ease;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.weapon-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.weapon-card img {
  width: 100%;
  height: 80%;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 15px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}

.weapon-card h3 {
  color: #8b4513;
  margin-bottom: 15px;
  font-size: 22px;
  font-weight: bold;
}

.weapon-card p {
  font-size: 16px;
  margin-bottom: 20px;
  color: #5e4b3b;
}

.mini-game-card {
  background-color: rgba(250, 240, 230, 0.8);
  border: 3px solid #d2b48c;
  border-radius: 15px;
  padding: 25px;
  max-width: 450px;
  margin: 30px auto;
  text-align: center;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.mini-game-card h3 {
  color: #8b4513;
  margin-bottom: 15px;
  font-size: 24px;
  font-weight: bold;
}

.mini-game-card p {
  font-size: 18px;
  margin-bottom: 20px;
  color: #5e4b3b;
}

button {
  background-color: #8b4513;
  color: #ffffff;
  border: none;
  padding: 12px 25px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 18px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  margin: 8px;
  font-family: 'Cinzel', serif;
  text-transform: uppercase;
  letter-spacing: 1px;
}

button:hover {
  background-color: #a0522d;
  transform: translateY(-3px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

button:active {
  transform: translateY(1px);
}

.arrow-crafting {
  margin-top: 30px;
  padding: 20px;
  background-color: rgba(240, 240, 240, 0.8);
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.arrow-crafting h3 {
  margin-top: 0;
  color: #8b4513;
  font-size: 22px;
  font-weight: bold;
}

.arrow-crafting p {
  margin-bottom: 15px;
  color: #5e4b3b;
  font-size: 16px;
}

.arrow-crafting button {
  padding: 12px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.arrow-crafting button:hover:not(:disabled) {
  background-color: #45a049;
  transform: translateY(-3px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.arrow-crafting button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.legend-weapons-lvl4 .weapon-card .legend-resource-requirements {
  margin-top: 10px;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
}

.legend-weapons-lvl4 .weapon-card .legend-resource-requirements p {
  white-space: pre-line;
  margin: 0;
}

/* Modal styles for fullscreen mini-games */
.fullscreen-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.fullscreen-modal-content {
  background-color: #f4e9d5;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
}

.fullscreen-modal-close {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 24px;
  color: #fff;
  cursor: pointer;
  background: none;
  border: none;
  padding: 5px 10px;
}

.fullscreen-modal-close:hover {
  color: #ffd700;
}

/* Ensure content doesn't overflow horizontally */
* {
  max-width: 100%;
  box-sizing: border-box;
}