.rune-matching-game {
    font-family: Arial, sans-serif;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f4e1c1; /* Sand brown color */
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .rune-matching-game h2 {
    color: #000000;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .game-info {
    background-color: #1e6092;
    display: flex;
    color: #ffffff;
    justify-content: space-between;
    margin-bottom: 20px;
    font-size: 18px;
  }
  
  .game-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(8, 1fr);
    gap: 10px;
    max-width: 400px;
    margin: 0 auto;
  }
  
  .rune-card {
    width: 100%;
    aspect-ratio: 1;
    background-color: #d9af60; /* Light brown color */
    border: 2px solid #532d11; /* Darker brown for border */
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .rune-card:hover {
    transform: scale(1.05);
    box-shadow: 0 0 5px rgba(139, 69, 19, 0.3); /* Brown shadow */
  }
  
  .rune-card.selected {
    background-color: #1e6092; /* Slightly darker brown when selected */
  }
  
  .rune-card.matched {
    background-color: #014e01; /* Keep green for matched cards */
    cursor: default;
  }
  
  .game-over {
    text-align: center;
    margin-top: 20px;
  }
  
  .game-over p {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .play-again-btn {
    background-color: #8b4513; /* Brown color for button */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .play-again-btn:hover {
    background-color: #6e370f; /* Darker brown on hover */
  }
  
  @media (max-width: 500px) {
    .game-grid {
      gap: 5px;
    }
  
    .rune-card {
      font-size: 18px;
    }
  }