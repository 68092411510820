/* Ancient Egypt Warrior Mobile Game Theme - Advanced Weapon Crafting Styles */

:root {
  --wc-primary-color: #d4af37; /* Gold */
  --wc-secondary-color: #8b4513; /* Saddle Brown */
  --wc-background-color: #f4e1c1; /* Antique White */
  --wc-text-color: #2c1e0f; /* Dark Brown */
  --wc-border-color: #a0522d; /* Sienna */
  --wc-button-color: #c19a6b; /* Fallow */
  --wc-button-hover-color: #deb887; /* Burlywood */
  --wc-disabled-color: #a9a9a9; /* Dark Gray */
  --wc-resource-text-color: #ffffff; /* White */
  --wc-shadow-color: rgba(0, 0, 0, 0.2);
}

.wc-container {
  background-color: var(--wc-background-color);
  color: var(--wc-text-color);
  font-family: 'Papyrus', 'Copperplate', fantasy;
  padding: 20px;
  border: 2px solid var(--wc-border-color);
  border-radius: 10px;
  max-width: 100%;
  margin: 0 auto;
  box-shadow: 0 4px 8px var(--wc-shadow-color);
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100"><rect fill="%23d4af37" fill-opacity="0.1" width="50" height="50" x="0" y="0"/><rect fill="%23d4af37" fill-opacity="0.1" width="50" height="50" x="50" y="50"/></svg>');
  background-size: 20px 20px;
}

.wc-title {
  color: var(--wc-secondary-color);
  text-align: center;
  font-size: 1.8em;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: 2px 2px 4px var(--wc-shadow-color);
}

.wc-resources {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  box-shadow: inset 0 0 10px var(--wc-shadow-color);
}

.wc-resource-item {
  margin: 5px 0;
  font-size: 1em;
  color: var(--wc-resource-text-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wc-resource-item::before {
  content: '•';
  color: var(--wc-primary-color);
  font-size: 1.2em;
  margin-right: 5px;
}

.wc-crafting-options {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.wc-crafting-option {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px var(--wc-shadow-color);
  transition: transform 0.3s ease;
}

.wc-crafting-option:hover {
  transform: translateY(-2px);
}

.wc-requirements {
  font-size: 0.9em;
  margin-bottom: 10px;
  text-align: center;
  color: var(--wc-secondary-color);
  font-style: italic;
}

.wc-craft-button {
  background-color: var(--wc-button-color);
  color: var(--wc-text-color);
  border: none;
  padding: 12px 20px;
  font-size: 1em;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 5px;
  font-family: inherit;
  text-transform: uppercase;
  letter-spacing: 1px;
  width: 100%;
  touch-action: manipulation;
  position: relative;
  overflow: hidden;
}

.wc-craft-button::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(255,255,255,0.3) 0%, rgba(255,255,255,0) 70%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.wc-craft-button:active::after {
  opacity: 1;
}

.wc-craft-button:active {
  background-color: var(--wc-button-hover-color);
  transform: scale(0.98);
}

.wc-craft-button:disabled {
  background-color: var(--wc-disabled-color);
  color: #666;
  cursor: not-allowed;
}

.wc-progress-message {
  font-style: italic;
  color: var(--wc-secondary-color);
  text-align: center;
  margin-top: 15px;
  font-size: 0.9em;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% { opacity: 0.6; }
  50% { opacity: 1; }
  100% { opacity: 0.6; }
}

/* Egyptian-inspired decorative elements */
.wc-container::before,
.wc-container::after {
  content: "☥";  /* Ankh symbol */
  font-size: 2em;
  color: var(--wc-secondary-color);
  display: block;
  text-align: center;
  margin: 10px 0;
  text-shadow: 2px 2px 4px var(--wc-shadow-color);
}

/* Responsive adjustments */
@media (max-width: 320px) {
  .wc-container {
    padding: 15px;
  }

  .wc-title {
    font-size: 1.5em;
  }

  .wc-craft-button {
    font-size: 0.9em;
    padding: 10px 15px;
  }

  .wc-resource-item {
    font-size: 0.9em;
  }
} 