.skill-selection {
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(255, 215, 0, 0.3);
  max-width: 100%;
  margin: 0 auto;
  overflow-y: auto;
  max-height: 90vh;
}

.skill-selection h2 {
  text-align: center;
  color: #ffd700;
  font-size: 24px;
  margin-bottom: 20px;
  text-shadow: 0 0 10px rgba(255, 215, 0, 0.5);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

.skill-options {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.skill-card {
  background-color: #1a1a1a;
  border: 2px solid #ffd700;
  border-radius: 8px;
  padding: 15px;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.skill-card::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(255,215,0,0.1) 0%, rgba(255,215,0,0) 70%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.skill-card:hover::before {
  opacity: 1;
}

.skill-card:active {
  transform: scale(0.98);
}

.skill-card h3 {
  color: #ffd700;
  font-size: 18px;
  margin-bottom: 10px;
}

.skill-card p {
  color: #ccc;
  font-size: 14px;
  margin-bottom: 10px;
}

.skill-category {
  display: inline-block;
  background-color: #333;
  color: #ffd700;
  padding: 3px 8px;
  border-radius: 12px;
  font-size: 12px;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

.skill-card {
  animation: fadeIn 0.5s ease-out;
  animation-fill-mode: both;
}

.skill-card:nth-child(1) { animation-delay: 0.1s; }
.skill-card:nth-child(2) { animation-delay: 0.2s; }
.skill-card:nth-child(3) { animation-delay: 0.3s; }

@media (min-width: 768px) {
  .skill-options {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .skill-card {
    width: calc(33.33% - 20px);
    max-width: 300px;
  }
}

/* Scrollbar Styles */
.skill-selection::-webkit-scrollbar {
  width: 8px;
}

.skill-selection::-webkit-scrollbar-track {
  background: #1a1a1a;
}

.skill-selection::-webkit-scrollbar-thumb {
  background: #ffd700;
  border-radius: 4px;
}

.skill-selection::-webkit-scrollbar-thumb:hover {
  background: #ffea00;
}