.good-weapon-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .good-weapon-modal {
    background-color: #f0e68c;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    position: relative;
    animation: modalAppear 0.5s ease-out;
  }
  
  .good-weapon-content {
    position: relative;
  }
  
  .good-weapon-image {
    width: 200px;
    height: 200px;
    margin: 20px auto;
    animation: itemFloat 2s ease-in-out infinite;
  }
  
  .good-weapon-image img {
    max-width: 100%;
    max-height: 100%;
  }
  
  .sparkles {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
  }
  
  .sparkles::before,
  .sparkles::after {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: gold;
    animation: sparkle 1s infinite;
  }
  
  @keyframes modalAppear {
    from { transform: scale(0.8); opacity: 0; }
    to { transform: scale(1); opacity: 1; }
  }
  
  @keyframes itemFloat {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-20px); }
  }
  
  @keyframes sparkle {
    0%, 100% { opacity: 0; transform: scale(0); }
    50% { opacity: 1; transform: scale(1); }
  }
  
  /* Add more sparkles */
  .sparkles::before { left: 20%; top: 20%; animation-delay: 0s; }
  .sparkles::after { right: 20%; bottom: 20%; animation-delay: 0.5s; }