:root {
  --primary-color: #8b7a41; /* Egyptian gold */
  --secondary-color: #443d12b8; /* Deep blue of the Nile */
  --background-color: #6c4e2e; /* Dark sand color */
  --text-color: #f0e6d2; /* Papyrus color */
  --accent-color: #b22222; /* Egyptian red */
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: 'Papyrus', sans-serif;
  margin: 0;
  padding: 0;
  background-image: url('/images/backgrounds/bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  min-height: 100vh;
  overflow-x: hidden;
}

/* Rest of the CSS remains the same */

/* You may need to add this if there's still some horizontal movement */
html, body {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
}

/* Ensure the content doesn't cause horizontal scrolling */
.content-wrapper {
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
}

/* Add this if you have any absolutely positioned elements that might be causing overflow */
* {
  box-sizing: border-box;
}

.top-section {
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px 5px;
}

.main-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.icon-button {
  background: none;
  border: none;
  padding: 5px;
}

.icon-button img {
  width: 28px;
  height: 28px;
}

.profile-button {
  background-color: #4a4a4a;
  color: #fff;
  border: none;
  padding: 8px 15px;
  border-radius: 20px;
  font-size: 16px;
  font-weight: bold;
}

.player-stats {
  display: flex;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  padding: 10px 18px;
}

.stat-item {
  display: flex;
  align-items: center;
  color: #fff;
}

.stat-item img {
  width: 20px;
  height: 20px;
  margin-right: 6px;
}

.stat-item span {
  font-size: 14px;
  font-weight: bold;
}

/* For slightly larger mobile devices */
@media (min-width: 360px) {
  .icon-button img {
    width: 32px;
    height: 32px;
  }

  .profile-button {
    font-size: 18px;
    padding: 10px 20px;
  }

  .stat-item img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

  .stat-item span {
    font-size: 16px;
  }
}
.round-button img {
  width: 20px;
  height: 20px;
}

.round-button:active, .profile-button:active {
  background-color: #5a5a50;
  border-color: #e5c100;
  transform: scale(0.95);
}

/* For devices with a bit more screen space */
@media (min-width: 375px) {
  .stat-item span {
    font-size: 14px;
  }

  .round-button, .profile-button {
    width: 45px;
    height: 45px;
  }

  .profile-button {
    width: auto;
  }
}

/* For larger mobile devices or small tablets */
@media (min-width: 480px) {
  .top-section {
    padding: 15px 10px;
  }

  .player-stats {
    padding: 8px 15px;
  }

  .stat-item {
    padding: 3px 8px;
  }

  .stat-item img {
    width: 24px;
    height: 24px;
  }

  .stat-item span {
    font-size: 16px;
  }

  .round-button, .profile-button {
    width: 50px;
    height: 50px;
    font-size: 14px;
  }

  .profile-button {
    width: auto;
    padding: 0 20px;
  }
}


button {
  background-color: #3b3a34; /* Dark sandstone color */
  color: #f0b158;
  border: 2px solid #ffd700; /* Gold border */
  border-radius: 8px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s, transform 0.2s;
  display: inline-block;
  text-align: center;
  outline: none;
}

.button:hover {
  background-color: #5a5a50; /* Lighter sandstone color */
  border-color: #e5c100; /* Slightly darker gold */
  transform: scale(1.05);
}

.button:active {
  background-color: #6a6a5d; /* Medium sandstone color */
  border-color: #ffd700; /* Gold border */
}

.round-button {
  background-color: #3b3a34; /* Dark sandstone color */
  color: #ffd700; /* Gold color */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: 2px solid #ffd700; /* Gold border */
  transition: background-color 0.3s, border-color 0.3s;
}

.round-button img {
  width: 20px;
  height: 20px;
}

.round-button:hover {
  background-color: #5a5a50; /* Lighter sandstone color */
  border-color: #e5c100; /* Slightly darker gold */
}

.ancient-button {
  background-color: #6a6a5d; /* Medium sandstone color */
  color: #f5f5f5;
  border: 2px solid #ffd700; /* Gold border */
  border-radius: 10px;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s, transform 0.2s;
  display: inline-block;
  text-align: center;
  outline: none;
}

.ancient-button:hover {
  background-color: #5a5a50; /* Lighter sandstone color */
  border-color: #e5c100; /* Slightly darker gold */
  transform: scale(1.05);
}

.ancient-button:active {
  background-color: #6a6a5d; /* Medium sandstone color */
  border-color: #ffd700; /* Gold border */
}

.start-button {
  background-color: #6a6a5d; /* Medium sandstone color */
  color: #f5f5f5;
  border: 2px solid #ffd700; /* Gold border */
  border-radius: 10px;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s, transform 0.2s;
  display: inline-block;
  text-align: center;
  outline: none;
}

.tab-button {
  background-color: #947c47; /* Medium sandstone color */
  color: #f5f5f5;
  border: 1px solid #ffd700; /* Gold border */
  border-radius: 5px;
  padding: 8px 8px;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s, transform 0.2s;
  display: inline-block;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.tab-button:hover {
  background-color: #5a5a50; /* Lighter sandstone color */
  border-color: hsla(50, 70%, 19%, 0.507); /* Slightly darker gold */
  transform: scale(1.05);
}

.tab-button.active {
  background-color: #383525; /* Gold color */
  color: #000;
}

.profile-modal-button {
  background-color: #ffd700; /* Gold color */
  color: #000;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.profile-modal-button:hover {
  background-color: #e5c100; /* Slightly darker gold */
  transform: scale(1.05);
}

.profile-modal-button:active {
  background-color: #ffd700; /* Gold color */
  transform: scale(0.95);
}

.modal-button {
  background-color: #3b3a34; /* Dark sandstone color */
  color: #ffd700; /* Gold color */
  border: 2px solid #ffd700; /* Gold border */
  border-radius: 8px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s, transform 0.2s;
  display: inline-block;
  text-align: center;
}

.modal-button:hover {
  background-color: #5a5a50; /* Lighter sandstone color */
  border-color: #e5c100; /* Slightly darker gold */
  transform: scale(1.05);
}

.modal-button:active {
  background-color: #6a6a5d; /* Medium sandstone color */
  border-color: #ffd700; /* Gold border */
}
.tabs {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--secondary-color);
  padding: 10px 0;
  z-index: 1000;
  height: 100px;
}

.tabs::-webkit-scrollbar {
  display: none;
}

/* Add this new rule for the individual tab items */
.tabs > * {
  min-width: 100px;
  height: 100px;
  flex-shrink: 0;
}

:root {
  --primary-color: #8b7a41; /* Egyptian gold */
  --secondary-color: #443d12b8; /* Deep blue of the Nile */
  --background-color: #6c4e2e; /* Dark sand color */
  --text-color: #f0e6d2; /* Papyrus color */
  --accent-color: #b22222; /* Egyptian red */
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: 'Papyrus', sans-serif;
  margin: 0;
  padding: 0;
  background-image: url('/images/backgrounds/bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  min-height: 100vh;
  overflow-x: hidden;
}

/* Rest of the CSS remains the same */

/* You may need to add this if there's still some horizontal movement */
html, body {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
}

/* Ensure the content doesn't cause horizontal scrolling */
.content-wrapper {
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
}

/* Add this if you have any absolutely positioned elements that might be causing overflow */
* {
  box-sizing: border-box;
}

.top-section {
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px 5px;
}

.main-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.icon-button {
  background: none;
  border: none;
  padding: 5px;
}

.icon-button img {
  width: 28px;
  height: 28px;
}

.profile-button {
  background-color: #4a4a4a;
  color: #fff;
  border: none;
  padding: 8px 15px;
  border-radius: 20px;
  font-size: 16px;
  font-weight: bold;
}

.player-stats {
  display: flex;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  padding: 8px 15px;
}

.stat-item {
  display: flex;
  align-items: center;
  color: #fff;
}

.stat-item img {
  width: 20px;
  height: 20px;
  margin-right: 6px;
}

.stat-item span {
  font-size: 14px;
  font-weight: bold;
}

/* For slightly larger mobile devices */
@media (min-width: 360px) {
  .icon-button img {
    width: 32px;
    height: 32px;
  }

  .profile-button {
    font-size: 18px;
    padding: 10px 20px;
  }

  .stat-item img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

  .stat-item span {
    font-size: 16px;
  }
}
.round-button img {
  width: 20px;
  height: 20px;
}

.round-button:active, .profile-button:active {
  background-color: #5a5a50;
  border-color: #292063;
  transform: scale(0.95);
}

/* For devices with a bit more screen space */
@media (min-width: 375px) {
  .stat-item span {
    font-size: 14px;
  }

  .round-button, .profile-button {
    width: 45px;
    height: 45px;
  }

  .profile-button {
    width: auto;
  }
}

/* For larger mobile devices or small tablets */
@media (min-width: 480px) {
  .top-section {
    padding: 15px 10px;
  }

  .player-stats {
    padding: 8px 15px;
  }

  .stat-item {
    padding: 3px 8px;
  }

  .stat-item img {
    width: 24px;
    height: 24px;
  }

  .stat-item span {
    font-size: 16px;
  }

  .round-button, .profile-button {
    width: 50px;
    height: 50px;
    font-size: 14px;
  }

  .profile-button {
    width: auto;
    padding: 0 20px;
  }
}


button {
  background-color: #3b3a34; /* Dark sandstone color */
  color: #f0b158;
  border: 2px solid #ffd700; /* Gold border */
  border-radius: 8px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s, transform 0.2s;
  display: inline-block;
  text-align: center;
  outline: none;
}

.button:hover {
  background-color: #5a5a50; /* Lighter sandstone color */
  border-color: #e5c100; /* Slightly darker gold */
  transform: scale(1.05);
}

.button:active {
  background-color: #6a6a5d; /* Medium sandstone color */
  border-color: #ffd700; /* Gold border */
}

.round-button {
  background-color: #3b3a34; /* Dark sandstone color */
  color: #ffd700; /* Gold color */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: 2px solid #ffd700; /* Gold border */
  transition: background-color 0.3s, border-color 0.3s;
}

.round-button img {
  width: 20px;
  height: 20px;
}

.round-button:hover {
  background-color: #5a5a50; /* Lighter sandstone color */
  border-color: #e5c100; /* Slightly darker gold */
}

.ancient-button {
  background-color: #6a6a5d; /* Medium sandstone color */
  color: #f5f5f5;
  border: 2px solid #ffd700; /* Gold border */
  border-radius: 10px;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s, transform 0.2s;
  display: inline-block;
  text-align: center;
  outline: none;
}

.ancient-button:hover {
  background-color: #5a5a50; /* Lighter sandstone color */
  border-color: #e5c100; /* Slightly darker gold */
  transform: scale(1.05);
}

.ancient-button:active {
  background-color: #6a6a5d; /* Medium sandstone color */
  border-color: #ffd700; /* Gold border */
}

.start-button {
  background-color: #6a6a5d; /* Medium sandstone color */
  color: #f5f5f5;
  border: 2px solid #ffd700; /* Gold border */
  border-radius: 10px;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s, transform 0.2s;
  display: inline-block;
  text-align: center;
  outline: none;
}

.tab-button {
  background-color: #947c47; /* Medium sandstone color */
  color: #f5f5f5;
  border: 1px solid #ffd700; /* Gold border */
  border-radius: 5px;
  padding: 8px 8px;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s, transform 0.2s;
  display: inline-block;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.tab-button:hover {
  background-color: #5a5a50; /* Lighter sandstone color */
  border-color: hsla(50, 70%, 19%, 0.507); /* Slightly darker gold */
  transform: scale(1.05);
}

.tab-button.active {
  background-color: #383525; /* Gold color */
  color: #000;
}

.profile-modal-button {
  background-color: #ffd700; /* Gold color */
  color: #000;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.profile-modal-button:hover {
  background-color: #e5c100; /* Slightly darker gold */
  transform: scale(1.05);
}

.profile-modal-button:active {
  background-color: #ffd700; /* Gold color */
  transform: scale(0.95);
}

.modal-button {
  background-color: #3b3a34; /* Dark sandstone color */
  color: #ffd700; /* Gold color */
  border: 2px solid #ffd700; /* Gold border */
  border-radius: 8px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s, transform 0.2s;
  display: inline-block;
  text-align: center;
}

.modal-button:hover {
  background-color: #5a5a50; /* Lighter sandstone color */
  border-color: #e5c100; /* Slightly darker gold */
  transform: scale(1.05);
}

.modal-button:active {
  background-color: #6a6a5d; /* Medium sandstone color */
  border-color: #ffd700; /* Gold border */
}



/* Profile Overlay */
.profile-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
}

.profile-card {
  background: linear-gradient(145deg, #3b3a34, #6c4e2e);
  border: 2px solid #ffd700;
  padding: 30px;
  border-radius: 15px;
  color: #f0e6d2;
  text-align: center;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
  width: 90%;
  max-width: 400px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-card h2 {
  color: #ffd700;
  margin-bottom: 20px;
  font-size: 2rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.close-button {
  position: absolute;
  top: 3px;
  left: 130px;
  background: none;
  border: none;
  color: #ffd700;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 5px;
  line-height: 1;
  transition: transform 0.3s ease;
  z-index: 1;
}

.close-button:hover {
  transform: rotate(-90deg);
}

.character-section {
  margin: 20px 0;
  position: relative;
}

.player-name {
  font-size: 1.5em;
  margin-top: 10px;
}

.glow-blue {
  color: #fff;
  animation: glow-blue 1.5s ease-in-out infinite alternate;
}

.glow-pink {
  color: #fff;
  animation: glow-pink 1.5s ease-in-out infinite alternate;
}

/* Level-based glow intensities */
.level-1 {
  filter: drop-shadow(0 0 2px var(--glow-color));
}

.level-2 {
  filter: drop-shadow(0 0 4px var(--glow-color)) drop-shadow(0 0 6px var(--glow-color));
}

.level-3 {
  filter: drop-shadow(0 0 6px var(--glow-color)) drop-shadow(0 0 8px var(--glow-color)) drop-shadow(0 0 10px var(--glow-color));
}

.level-4 {
  filter: drop-shadow(0 0 8px var(--glow-color)) drop-shadow(0 0 10px var(--glow-color)) drop-shadow(0 0 12px var(--glow-color)) drop-shadow(0 0 14px var(--glow-color));
}

@keyframes glow-blue {
  from {
    --glow-color: #00bfff;
  }
  to {
    --glow-color: #0080ff;
  }
}

@keyframes glow-pink {
  from {
    --glow-color: #ff00de;
  }
  to {
    --glow-color: #ff1493;
  }
}


.character-image {
  width: 90%;
  max-width: 200px;
  height: auto;
  margin: 0 auto;
  display: block;

}

.skins-section {
  margin-top: 30px;
  width: 100%;
}

.skins-section h3 {
  color: #ffd700;
  font-size: 1.5rem;
  margin-bottom: 15px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.skins-section button {
  background: linear-gradient(145deg, #3b3a34, #6c4e2e);
  color: #ffd700;
  border: 1px solid #ffd700;
  padding: 8px 12px;
  margin: 3px;
  border-radius: 15px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 0.8rem;
}

.skins-section button:hover:not(:disabled) {
  background: linear-gradient(145deg, #6c4e2e, #3b3a34);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(255, 215, 0, 0.3);
}

.skins-section button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.invertory-button, .skill-button {
  background: linear-gradient(145deg, #ffd700, #e5c100);
  color: #3b3a34;
  border: none;
  padding: 12px 20px;
  margin-top: 20px;
  border-radius: 25px;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.3s ease;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.invertory-button:hover, .skill-button:hover {
  background: linear-gradient(145deg, #e5c100, #ffd700);
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(255, 215, 0, 0.3);
}


.bottom-section {
  padding: 20px;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--background-color);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  color: var(--text-color);
}

.modal button {
  background: var(--primary-color);
  color: var(--background-color);
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
}

.modal button:hover {
  background: var(--accent-color);
}

  .timer-display {
    position: relative;
    z-index: 1001;
  }
  
  /* Ensure other elements don't overlap with the centered timer */
  .game-container,
  .top-section,
  .bottom-section,
  .activity-cards {
    position: relative;
    z-index: 1;
  }

.timer-display {
  font-family: 'Orbitron', sans-serif;
  left: 50%;
  transform: translate(-50%, 10%);
  font-size: 3rem;
  font-weight: bold;
  color: #ffd700;
  text-shadow: 0 0 20px rgba(255, 215, 0, 0.7);
  background: linear-gradient(145deg, #2a2a2a, #1a1a1a);
  padding: 15px 60px;
  border-radius: 30px;
  box-shadow: 
    0 8px 12px rgba(0, 0, 0, 0.1),
    0 3px 6px rgba(0, 0, 0, 0.08),
    inset 0 4px 4px rgba(255, 255, 255, 0.1);
  display: inline-block;
  transition: all 0.3s ease;
  z-index: 1; /* Ensure the timer display is behind other elements */
}

.timer-display:hover {
  
  box-shadow: 
    0 14px 28px rgba(0, 0, 0, 0.1),
    0 10px 10px rgba(0, 0, 0, 0.08),
    inset 0 4px 4px rgba(255, 255, 255, 0.1);
}
.tab-button {
  position: relative;
}

.notification-dot {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
}

/* Activity cards container */
.activity-cards {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  gap: 10px;
  justify-content: flex-start;
  scroll-snap-type: x mandatory;
  padding: 10px 10px;
}

/* Activity card styles */
.activity-card {
  display: flex;
  flex-direction: column;
  border: 2px solid #ffd700;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s, filter 0.3s;
  background: rgba(255, 255, 255, 0.2);
  max-width: 420px;
  margin: 1rem;
  position: relative;
  z-index: 2;
  flex: 0 0 auto;
  width: 240px;
  height: 360px;
  scroll-snap-align: start;
}

.activity-card:hover {
  transform: translateY(-5px);
}

.activity-card .activity-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.activity-card .activity-content {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  color: #fff;
  flex-grow: 1;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
}

.activity-card h2 {
  font-size: 1.8rem;
  margin: 0 0 1rem;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
}

.activity-card p {
  font-size: 1.2rem;
  color: #fff;
  margin-bottom: 1rem;
  flex-grow: 2;
  text-shadow: 4px 4px 8px rgba(0, 0, 0, 1.8);
}

.activity-card button {
  padding: 0.75rem 1rem;
  background-color: #32652b;
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: auto;
  width: 80%; /* Added to make button wider */
}

.activity-card button:hover:not(:disabled) {
  background-color: #00b36b;
}

.activity-card button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.activity-card button:focus,
.activity-card button:active {
  outline: none;
  background-color: #32652b;
  transform: translateX(-50%);
}

.activity-card .required-level {
  color: #ff6b6b;
  font-weight: bold;
}

.activity-card.level-locked:not(.inactive) .required-level {
  opacity: 1;
  visibility: visible;
}

.activity-card:not(.level-locked) .required-level,
.activity-card.inactive .required-level,
.activity-card.level-locked:not(.inactive) .required-level:not(:hover) {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
}

/* Show level requirement on hover for level 3+ players */
.activity-card.level-locked:not(.inactive) .required-level:hover {
  opacity: 1;
  visibility: visible;
}
[lang="fa"] {
  direction: rtl;
  text-align: right;
}

.activity-card .required-level {
  font-size: 0.9rem;
  color: #fc0000; /* Changed to a more visible color */
  text-shadow: 
        -1px -1px 0 rgb(255, 255, 255),
        1px -1px 0 black,
        -1px 1px 0 rgb(255, 255, 255),
        1px 1px 0 black;
  text-align: center;
  margin-top: auto;
  margin-bottom: 3rem; /* Added to push it above the button */
  font-weight: bold; /* Added to make it more prominent */
}

.activity-card.inactive {
  opacity: 0.7;
}

/* Hide scrollbars for a cleaner look */
.activity-cards::-webkit-scrollbar {
  display: none;
}

.bottom-section {
  padding: 20px;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--background-color);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  color: var(--text-color);
}

.modal button {
  background: var(--primary-color);
  color: var(--background-color);
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
}

.modal button:hover {
  background: var(--accent-color);
}

  .timer-display {
    position: relative;
    z-index: 1001;
  }
  
  /* Ensure other elements don't overlap with the centered timer */
  .game-container,
  .top-section,
  .bottom-section,
  .activity-cards {
    position: relative;
    z-index: 1;
  }

.timer-display {
  font-family: 'Orbitron', sans-serif;
  left: 50%;
  transform: translate(-50%, 10%);
  font-size: 3rem;
  font-weight: bold;
  color: #ffd700;
  text-shadow: 0 0 20px rgba(255, 215, 0, 0.7);
  background: linear-gradient(145deg, #2a2a2a, #1a1a1a);
  padding: 15px 60px;
  border-radius: 30px;
  box-shadow: 
    0 8px 12px rgba(0, 0, 0, 0.1),
    0 3px 6px rgba(0, 0, 0, 0.08),
    inset 0 4px 4px rgba(255, 255, 255, 0.1);
  display: inline-block;
  transition: all 0.3s ease;
  z-index: 1; /* Ensure the timer display is behind other elements */
}

.timer-display:hover {
  
  box-shadow: 
    0 14px 28px rgba(0, 0, 0, 0.1),
    0 10px 10px rgba(0, 0, 0, 0.08),
    inset 0 4px 4px rgba(255, 255, 255, 0.1);
}
.tab-button {
  position: relative;
}

.notification-dot {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
}