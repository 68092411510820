.wallet-modal {
    background-color: #f4e1c1;
    border: 4px solid #d4af37;
    border-radius: 10px;
    padding: 20px;
    width: 90%;
    max-width: 400px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-family: 'Papyrus', 'Copperplate', fantasy;
    color: #8b4513;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    overflow: hidden;
  }
  
  .wallet-modal h2 {
    font-size: 24px;
    margin-bottom: 15px;
    color: #d4af37;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  }
  
  .wallet-modal p {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .wallet-modal button {
    background-color: #d4af37;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    margin-top: 15px;
  }
  
  .wallet-modal button:hover {
    background-color: #b8860b;
  }
  
  @keyframes glow {
    0% { box-shadow: 0 0 5px #d4af37; }
    50% { box-shadow: 0 0 20px #d4af37; }
    100% { box-shadow: 0 0 5px #d4af37; }
  }
  
  .wallet-modal {
    animation: glow 2s infinite;
  }