:root {
  --primary-color: #4a90e2; /* Light blue */
  --secondary-color: #f1c40f; /* Gold */
  --background-color: #e6f2ff; /* Very light blue */
  --text-color: #2c3e50; /* Dark blue for text */
  --completed-color: #2ecc71; /* Green for completed tasks */
  --card-background: #ffffff;
  --description-color: #34495e; /* Slightly lighter than text-color for descriptions */
}

.social-tasks {
  background-color: var(--background-color);
  padding: 16px;
  font-family: 'Roboto', sans-serif;
  color: var(--text-color);
  max-width: 100%;
  box-sizing: border-box;
  border: 1px solid var(--secondary-color);
  border-radius: 8px;
}

.social-tasks-header {
  color: var(--primary-color);
  text-align: center;
  font-size: 1.5em;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-shadow: 1px 1px 2px var(--secondary-color);
}

.task-item {
  background-color: var(--card-background);
  margin-bottom: 16px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid var(--secondary-color);
}

.task-title {
  background-color: var(--primary-color);
  color: var(--card-background);
  padding: 12px 16px;
  font-size: 1.1em;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  border-bottom: 2px solid var(--secondary-color);
}

.task-info {
  padding: 16px;
}

.task-icon {
  font-size: 1.5em;
  margin-right: 12px;
  color: var(--secondary-color);
}

.task-details p {
  margin: 0 0 8px 0;
  font-size: 0.9em;
  color: var(--description-color);
}

.task-reward {
  font-weight: bold;
  color: var(--primary-color);
  font-size: 1em;
}

.task-button {
  width: 100%;
  padding: 12px 16px;
  font-size: 1em;
  background-color: var(--primary-color);
  color: var(--card-background);
  border: 1px solid var(--secondary-color);
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.task-button:active {
  background-color: #3a7bc8;
}

.task-button.completed {
  background-color: var(--completed-color);
}

.task-button:disabled {
  background-color: #bdc3c7;
  color: #7f8c8d;
  border-color: #bdc3c7;
  cursor: not-allowed;
}

/* Gold accent lines */
.social-tasks::before,
.social-tasks::after {
  content: '';
  display: block;
  height: 2px;
  background-color: var(--secondary-color);
  margin: 16px 0;
}

/* Media query for larger screens */
@media screen and (min-width: 768px) {
  .social-tasks {
    max-width: 600px;
    margin: 0 auto;
  }

  .task-item {
    display: flex;
    flex-direction: column;
  }

  .task-info {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .task-button {
    width: auto;
    padding: 12px 24px;
  }
}