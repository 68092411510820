body {
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.hilt-crafting-puzzle {
  font-family: Arial, sans-serif;
  max-width: 400px;
  height: 100vh;
  margin: 0 0 0 auto;
  padding: 20px;
  background-color: #d2b48c;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h2 {
  color: #8b4513;
  margin-bottom: 10px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  font-size: 1.2em;
}

.game-info {
  font-size: 14px;
  margin-bottom: 10px;
  color: #8b4513;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5);
}

.cell, .gem-holder {
  width: 35px;
  height: 35px;
  border: 2px solid #8b4513;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #f5deb3;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.cell:hover, .gem-holder:hover {
  background-color: #deb887;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
}

.gem-image {
  width: 90%;
  height: 90%;
  object-fit: contain;
}

button {
  background-color: #8b4513;
  color: white;
  border: none;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

button:hover {
  background-color: #a0522d;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}