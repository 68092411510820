.character-creation {
    text-align: center;
    padding: 20px;
  }
  
  .character-creation div {
    margin: 10px 0;
  }
  
  .character-creation button {
    margin: 5px;
    padding: 10px 20px;
    font-size: 16px;
  }
  
  .character-creation input {
    padding: 10px;
    font-size: 16px;
    margin: 10px 0;
  }
  
  .selected {
    background-color: #ccc;
  }
  