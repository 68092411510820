.inventory-modal {
  font-family: Arial, sans-serif;
  color: #333;
  max-width: 800px;
  margin: 0 auto;
  background-color: #f0f0f0; /* Added grey background color */
}

.inventory-tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  border-bottom: 2px solid #e0e0e0;
}

.inventory-tabs button {
  background: none;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
}

.inventory-tabs button:hover {
  color: #4a90e2;
}

.inventory-tabs button::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #4a90e2;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.inventory-tabs button:hover::after,
.inventory-tabs button:focus::after {
  transform: scaleX(1);
}

.inventory-items {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 20px;
  padding: 20px;
}

.inventory-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  transition: all 0.3s ease;
  background-color: #fff;
}

.inventory-item:hover {
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
  transform: translateY(-5px);
}

.item-icon {
  width: 60px;
  height: 60px;
  object-fit: contain;
  margin-bottom: 10px;
}

.inventory-item span {
  font-size: 14px;
  color: #666;
}

.inventory-skins {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 15px;
  padding: 20px;
}

.skin-item {
  background-color: #f5f5f5;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 15px;
  transition: all 0.3s ease;
}

.skin-item:hover {
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
  transform: translateY(-2px);
}

.skin-item label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.skin-item input[type="radio"] {
  margin-right: 10px;
}

.skin-name {
  font-size: 14px;
  color: #333;
}

.set-skin-button {
  display: block;
  width: calc(100% - 40px);
  margin: 20px auto 0;
  background-color: #4a90e2;
  color: white;
  border: none;
  padding: 12px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.set-skin-button:hover {
  background-color: #357abd;
}

@media (max-width: 600px) {
  .inventory-skins {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }

  .skin-item {
    padding: 10px;
  }

  .skin-name {
    font-size: 12px;
  }
}