/* Modal.css */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: rgba(158, 56, 8, 0) !important; /* Force black background */
  color: #dcd0b1; /* Light text color for contrast */
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 600px;
  box-shadow: 0 2px 10px rgba(255, 255, 255, 0.1);
  animation: slide-down 0.3s ease-out forwards;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  border-bottom: 1px solid #dcd0b1;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #dcd0b1;
}

.modal-body {
  max-height: 70vh;
  overflow-y: auto;
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(88, 41, 14, 0.822) !important; /* Force black background */
  color: #dcd0b1;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(255, 255, 255, 0.1);
  z-index: 1000;
}

.modal h4 {
  margin-top: 0;
  color: #d4af37; /* Gold color for headers */
}

.modal button {
  display: block;
  margin: 10px 0;
  background-color: #d4af37; /* Gold color */
  color: black;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.modal button:hover {
  background-color: #ffd700; /* Brighter gold on hover */
}