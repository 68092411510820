.ore-mining-game {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-position: center;
  padding: 20px;
  box-sizing: border-box;
  user-select: none;  
}

.game-info {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.game-info p {
  margin: 5px 0;
  font-size: 18px;
  font-weight: bold;
}

.rock-grid {
  flex: 1;
  border-collapse: separate;
  border-spacing: 10px;
  width: 100%;
  height: 100%;
}

.rock-row {
  height: 33.33%;
}

.rock-cell {
  width: 33.33%;
  height: 33.33%;
  padding: 0;
  vertical-align: middle;
}


.rock {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: transform 0.1s, box-shadow 0.1s;
  -webkit-tap-highlight-color: transparent;  
  outline: none; 
}

.rock:active {
  transform: scale(0.95);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.rock img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: filter 0.3s;
  pointer-events: none;  
}

.rock:not(.broken):hover img {
  filter: brightness(1.2);
}

.rock.broken img {
  opacity: 0.6;
  filter: grayscale(100%);
}

.animation {
  position: absolute;
  font-size: 24px;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.7);
  pointer-events: none;
  animation: float-up 1s forwards;
  z-index: 10;
}

.animation.score {
  position: fixed;
  z-index: 1000;
  pointer-events: none;
  color: #00FFFF;
}

@keyframes float-up {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-50px);
  }
}

.game-over {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 48px;
  color: red;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border-radius: 10px;
}
.dust-particle {
  position: absolute;
  width: 10px;
  height: 10px;
  background: radial-gradient(circle, rgba(255,255,255,0.8) 0%, transparent 70%);
  border-radius: 50%;
  pointer-events: none;
  opacity: 0;
}

@keyframes dust-animation {
  0% {
    transform: translate(0, 0) scale(0.2);
    opacity: 1;
  }
  100% {
    transform: translate(var(--tx), var(--ty)) scale(2);
    opacity: 0;
  }
}

@media (max-width: 768px) {
  .game-info {
    font-size: 14px;
  }
  .animation {
    font-size: 18px;
  }
  .game-over {
    font-size: 36px;
  }
}