/* Market.css */
.market-container {
  padding: 10px 10px 70px;
  background-color: #c19f5b;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.market-tabs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.market-tab {
  flex: 0 0 auto;
  padding: 10px 15px;
  margin: 0 5px;
  background-color: #2c3e50;
  color: #93894a;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  text-transform: capitalize;
  cursor: pointer;
  transition: background-color 0.3s ease;
  white-space: nowrap;
}

.market-tab:hover,
.market-tab:active,
.market-tab.active {
  background-color: #1abc9c;
}

.panel-container {
  flex-grow: 1;
  overflow-y: auto;
  padding-bottom: 20px;
}

.panel {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  gap: 10px;
}

.card {
  background-color: rgb(202, 166, 103);
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.item-image-container {
  height: 100px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
}

.item-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  width: auto;
  height: auto;
}

.card-content {
  padding: 10px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.card h3 {
  font-size: 14px;
  margin: 0 0 5px;
  text-align: center;
}

.card p {
  margin: 3px 0;
  font-size: 12px;
  text-align: center;
}

.buy-button,
.info-button {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border: none;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.buy-button {
  background-color: #2ecc71;
  color: white;
}

.buy-button:hover {
  background-color: #27ae60;
}

.buy-button:disabled {
  background-color: #95a5a6;
  cursor: not-allowed;
}

.info-button {
  background-color: #3498db;
  color: white;
}

.info-button:hover {
  background-color: #2980b9;
}

.boost-active {
  color: #e74c3c;
  font-weight: bold;
  font-size: 11px;
}

@media (max-width: 320px) {
  .panel {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  }

  .card h3 {
    font-size: 12px;
  }

  .card p {
    font-size: 10px;
  }

  .buy-button,
  .info-button {
    font-size: 10px;
    padding: 6px;
  }
}