.entering-the-tomb.tomb {
  max-width: 100%;
  max-height: 100%;
  overflow-y: auto;
  padding: 20px;
  box-sizing: border-box;
  background-color: #2c3e50;
  color: #ecf0f1;
  font-family: 'Arial', sans-serif;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
  position: relative; /* Added for absolute positioning of controls */
}

.entering-the-tomb.tomb h2 {
  color: #f39c12;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
}

.entering-the-tomb.tomb .grid-container {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 10px;
  background-color: #34495e;
  border-radius: 5px;
  box-shadow: inset 0 0 10px rgba(0,0,0,0.5);
}

.entering-the-tomb.tomb .grid {
  display: grid;
  gap: 2px;
  width: 100%;
  height: 100%;
}

.entering-the-tomb.tomb .cell {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  border: 1px solid #2c3e50;
  border-radius: 3px;
  transition: all 0.3s ease;
}

.entering-the-tomb.tomb .cell.hidden {
  background-color: #2c3e50;
}

.entering-the-tomb.tomb .cell.safe {
  background-color: #27ae60;
}

.entering-the-tomb.tomb .cell.danger {
  background-color: #c0392b;
}

.entering-the-tomb.tomb .controls {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 5px;
  width: 120px;
  height: 120px;
  background-color: #34495e;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
}

.entering-the-tomb.tomb .controls button {
  font-size: 1.5em;
  width: 100%;
  height: 100%;
  border: none;
  cursor: pointer;
  background-color: #2c3e50;
  color: #ecf0f1;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.entering-the-tomb.tomb .controls button:hover {
  background-color: #3498db;
}

.entering-the-tomb.tomb .controls button:active {
  background-color: #2980b9;
  transform: scale(0.95);
}

.entering-the-tomb.tomb .controls button:nth-child(1) { grid-area: 1 / 2 / 2 / 3; }
.entering-the-tomb.tomb .controls button:nth-child(2) { grid-area: 3 / 2 / 4 / 3; }
.entering-the-tomb.tomb .controls button:nth-child(3) { grid-area: 2 / 1 / 3 / 2; }
.entering-the-tomb.tomb .controls button:nth-child(4) { grid-area: 2 / 3 / 3 / 4; }

.entering-the-tomb.tomb .gem-animation,
.entering-the-tomb.tomb .snake-bite-animation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.8em;
  font-weight: bold;
  animation: fadeUp 1s ease-out;
}

.entering-the-tomb.tomb .gem-animation {
  color: #f1c40f;
}

.entering-the-tomb.tomb .snake-bite-animation {
  color: #e74c3c;
}

@keyframes fadeUp {
  0% { opacity: 1; transform: translate(-50%, -50%); }
  100% { opacity: 0; transform: translate(-50%, -100%); }
}

.entering-the-tomb.tomb .earthquake {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2em;
  color: #e74c3c;
  animation: shake 0.5s ease-in-out infinite;
}

@keyframes shake {
  0%, 100% { transform: translate(-50%, -50%); }
  10%, 30%, 50%, 70%, 90% { transform: translate(-55%, -50%); }
  20%, 40%, 60%, 80% { transform: translate(-45%, -50%); }
}

@media (max-width: 600px) {
  .entering-the-tomb.tomb .grid-container {
    max-width: 300px;
  }
  
  .entering-the-tomb.tomb .controls {
    position: static;
    transform: none;
    margin: 20px auto;
    width: 100px;
    height: 100px;
  }
  
  .entering-the-tomb.tomb .controls button {
      font-size: 1.5em;
      width: 12px;
      height: 12px;
      border: none;
      cursor: pointer;
      background-color: #2c3e50;
      color: #ecf0f1;
      border-radius: 5px;
      transition: all 0.3s ease;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
    
  }
}