
.mini-games-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .mini-games-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .mini-games-header h2 {
    font-size: 24px;
    color: #333;
  }
  
  .total-score {
    font-size: 18px;
    font-weight: bold;
    color: #4a4a4a;
  }
  
  .game-cards-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  .game-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 300px;
    text-align: center;
    transition: transform 0.3s ease;
  }
  
  .game-card:hover {
    transform: translateY(-5px);
  }
  
  .game-card img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  
  .game-card h3 {
    font-size: 20px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .game-card p {
    font-size: 14px;
    color: #666;
    margin-bottom: 15px;
  }
  
  .game-card button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .game-card button:hover {
    background-color: #45a049;
  }
  
  .game-card.completed {
    border: 2px solid #4CAF50;
  }
  
  .nav-button {
    background-color: #ddd;
    border: none;
    color: #333;
    padding: 10px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .nav-button.prev {
    left: -60px;
  }
  
  .nav-button.next {
    right: -60px;
  }
  
  .game-over-modal {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
  }
  
  .game-over-modal h2 {
    font-size: 24px;
    color: #333;
    margin-bottom: 15px;
  }
  
  .game-over-modal p {
    font-size: 18px;
    color: #666;
    margin-bottom: 10px;
  }
  
  .game-over-modal button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-top: 15px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .game-over-modal button:hover {
    background-color: #45a049;
  }
  .no-scroll {
    overflow: hidden;
    position: fixed;
    width: 100%;
    height: 100%;
  }