/* Enhanced Ancient Egypt Map Exploration Styles */

:root {
  --map-primary-color: #d4af37; /* Gold */
  --map-secondary-color: #8b4513; /* Saddle Brown */
  --map-background-color: #f4e1c1; /* Antique White */
  --map-text-color: #2c1e0f; /* Dark Brown */
  --map-border-color: #a0522d; /* Sienna */
  --map-button-color: #c19a6b; /* Fallow */
  --map-button-hover-color: #deb887; /* Burlywood */
  --map-disabled-color: #a9a9a9; /* Dark Gray */
  --map-shadow-color: rgba(0, 0, 0, 0.3);
}

.ae-map-exploration {
  text-align: center;
  overflow: hidden;
  position: relative;
  padding: 20px;
  background-color: var(--map-background-color);
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100"><rect fill="%23d4af37" fill-opacity="0.1" width="50" height="50" x="0" y="0"/><rect fill="%23d4af37" fill-opacity="0.1" width="50" height="50" x="50" y="50"/></svg>');
  background-size: 20px 20px;
  font-family: 'Papyrus', 'Copperplate', fantasy;
}

.ae-card-container {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  gap: 20px;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.ae-card-container::-webkit-scrollbar {
  display: none;
}

.ae-map-card {
  flex: 0 0 80%;
  max-width: 300px;
  height: 400px;
  scroll-snap-align: center;
  position: relative;
  border: 2px solid var(--map-border-color);
  border-radius: 10px;
  cursor: pointer;
  padding: 10px;
  transition: transform 0.3s, border-color 0.3s;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0 4px 8px var(--map-shadow-color);
  overflow: hidden;
}

.ae-map-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%);
  z-index: 1;
}

.ae-map-card.selected {
  border-color: var(--map-primary-color);
  transform: scale(1.05);
  z-index: 2;
}

.ae-card-content {
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  text-align: left;
  color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  z-index: 2;
}

.ae-card-content h3 {
  margin: 0 0 10px 0;
  font-size: 1.5em;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
  -webkit-text-stroke: 1px white;
  text-stroke: 1px white;
}

.ae-card-content p {
  margin: 5px 0;
  font-size: 0.9em;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
}

.ae-tick-mark {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  color: var(--map-primary-color);
  z-index: 3;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
}

.ae-map-card.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.ae-map-card .ae-level-requirement {
  color: #ff6b6b;
  font-weight: bold;
}

.ae-nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(74, 55, 36, 0.7);
  color: #f0e6d2;
  border: none;
  padding: 15px;
  cursor: pointer;
  z-index: 10;
  border-radius: 50%;
  transition: background-color 0.3s, transform 0.3s;
}

.ae-nav-button:hover {
  background-color: rgba(74, 55, 36, 0.9);
  transform: translateY(-50%) scale(1.1);
}

.ae-nav-button.prev {
  left: 10px;
}

.ae-nav-button.next {
  right: 10px;
}

.ae-selected-region-info {
  background-color: rgba(255, 255, 255, 0.1);
  border: 2px solid var(--map-border-color);
  border-radius: 10px;
  padding: 15px;
  margin-top: 20px;
  box-shadow: 0 4px 8px var(--map-shadow-color);
}

.ae-selected-region-info h3 {
  color: var(--map-primary-color);
  margin-top: 0;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
}

.ae-selected-region-info p {
  color: var(--map-text-color);
  margin: 5px 0;
}

.ae-explore-button {
  background-color: var(--map-button-color);
  color: var(--map-text-color);
  border: none;
  padding: 10px 20px;
  margin-top: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  font-family: inherit;
  font-weight: bold;
}

.ae-explore-button:hover:not(:disabled) {
  background-color: var(--map-button-hover-color);
  transform: translateY(-2px);
}

.ae-explore-button:disabled {
  background-color: var(--map-disabled-color);
  cursor: not-allowed;
}
.ae-level-requirement.transparent {
  opacity: 0;
  visibility: hidden;
}
.ae-hunting-available {
  color: #4caf50;
  font-weight: bold;
}

@media (max-width: 600px) {
  .ae-map-card {
    flex: 0 0 90%;
    height: 350px;
  }

  .ae-nav-button {
    padding: 10px;
  }
}